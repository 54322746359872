import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Swal from "sweetalert2";
import { axiosInstance } from "index";
import { repeat } from "lodash";
import { fr } from "@faker-js/faker";

export interface BlogSinglePageProps {
  className?: string;
  header?: string;
  content?: string;
}

const KeralaPackage: FC<BlogSinglePageProps> = ({
  className = "",
  header,
  content,
}) => {
  const [packageRequested, setPackageRequested] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    message: "",
    packageRequested: "Ladakh",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value);
    setInitialValues({ ...initialValues, [name]: value });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/support/", initialValues);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Message Sent",
          text: "We will get back to you soon",
          showConfirmButton: true,
          confirmButtonColor: "#A47557",
          // timer: 800,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          confirmButtonColor: "#A47557",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="blue" name="Traveler" icon="Boat" type="package"/>
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Kerala Tour{" "}
          </h1>
          <div>
            <h6 className="hover:translate-x-1.5 delay-90">🍛Breakfast and Dinner🍱</h6>
            <h6 className="hover:translate-x-1.5">⛴Alleppey AC Deluxe </h6>
            <h6 className="hover:translate-x-1.5">⛵House boat stay🚢 </h6>
            <h6 className="hover:translate-x-1.5">🧍All prices per person💃</h6>
          </div>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div
          id="single-entry-content"
          className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        >
          <h3>Package Details</h3>
          <div className="grid grid-rows-8 md:grid-rows-4 grid-flow-col  gap-4 sm:d-block">
            <div
              // style={{
              //     border:"3px solid brown"
              // }}
              className="transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 border-2 border-slate-900 rounded-3xl shadow-lg shadow-indigo-500/50 drop-shadow-md hover:shadow-white hover:drop-shadow-x"
            >
              <ul>
                <strong>3N 4D 2N Munnar + 1N Alleppey Houseboat</strong>
                <li>2 pax Rs. 14,550/- per head</li>
                <li>3 pax Rs. 12,500/- per head</li>
                <li>4 pax Rs. 12,500/- per head</li>
                <li>5 pax Rs. 12,350/- per head</li>
                <li>6 pax Rs. 12,300/- per head</li>
              </ul>
            </div>

            <div className="transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 border-2 border-slate-900 rounded-3xl shadow-lg shadow-indigo-500/50 drop-shadow-md hover:shadow-white hover:drop-shadow-x">
              <ul>
                <strong>N 4D 2N Munnar + 1 Thekkady</strong>
                <li>2 pax Rs. 13,850/- per head</li>
                <li>3 pax Rs. 11,750/- per head</li>
                <li>4 pax Rs. 10,850/- per head</li>
                <li>5 pax Rs. 11,450/- per head</li>
                <li>6 pax Rs. 10,600/- per head</li>
              </ul>
            </div>

            <div className="transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 border-2 border-slate-900 rounded-3xl shadow-lg shadow-indigo-500/50 drop-shadow-md hover:shadow-white hover:drop-shadow-x">
              <ul>
                <strong>
                  4N 5D 2N Munnar + 1 Thekkady + 1N Alleppey Houseboat
                </strong>
                <li>2 pax Rs. 17,850/- per head</li>
                <li>3 pax Rs. 14,850/- per head</li>
                <li>4 pax Rs. 14,550/- per head</li>
                <li>5 pax Rs. 16,650/- per head</li>
                <li>6 pax Rs. 16,600/- per head</li>
              </ul>
            </div>

            <div className="transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 border-2 border-slate-900 rounded-3xl shadow-lg shadow-indigo-500/50 drop-shadow-md hover:shadow-white hover:drop-shadow-x">
              <ul>
                <strong>
                  5N 6D 1N Kochi + 2N Munnar + 1 Thekkady + 1N Alleppey
                  Houseboat
                </strong>
                <li>2 pax Rs. 20,900/- per head</li>
                <li>3 pax Rs. 17,450/- per head</li>
                <li>4 pax Rs. 17,000/- per head</li>
                <li>5 pax Rs. 16,550/- per head</li>
                <li>6 pax Rs. 16,550/- per head</li>
              </ul>
            </div>

            <div className="transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 border-2 border-slate-900 rounded-3xl shadow-lg shadow-indigo-500/50 drop-shadow-md hover:shadow-white hover:drop-shadow-x">
              <ul>
                <strong>
                  5N 6D 2N Munnar + 1 Thekkady + 1N Alleppey Houseboat + 1N
                  Kovalam
                </strong>
                <li>2 pax Rs. 24,350/- per head</li>
                <li>3 pax Rs. 19,700/- per head</li>
                <li>4 pax Rs. 18,850/- per head</li>
                <li>5 pax Rs. 18,100/- per head</li>
                <li>6 pax Rs. 17,850/- per head</li>
              </ul>
            </div>
            <div className="transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 border-2 border-slate-900 rounded-3xl shadow-lg shadow-indigo-500/50 drop-shadow-md hover:shadow-white hover:drop-shadow-x">
              <ul>
                <strong>
                  6N 7D 2N Munnar + 1 Thekkady + 1N Alleppey Houseboat + 2N
                  Kovalam
                </strong>
                <li>2 pax Rs. 26,900/- per head</li>
                <li>3 pax Rs. 21,900/- per head</li>
                <li>4 pax Rs. 19,350/- per head</li>
                <li>5 pax Rs. 20,600/- per head</li>
                <li>6 pax Rs. 20,100/- per head</li>
              </ul>
            </div>

            <div className="transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 border-2 border-slate-900 rounded-3xl shadow-lg shadow-indigo-500/50 drop-shadow-md hover:shadow-white hover:drop-shadow-x">
              <ul>
                <strong>
                  7N 8D 1N Kochi + 2N Munnar + 1 Thekkady + 1N Alleppey
                  Houseboat + 2N Kovalam
                </strong>
                <li>2 pax Rs. 30,250/- per head</li>
                <li>3 pax Rs. 24,750/- per head</li>
                <li>4 pax Rs. 23,900/- per head</li>
                <li>5 pax Rs. 23,600/- per head</li>
                <li>6 pax Rs. 23,100/- per head</li>
              </ul>
            </div>

            <div className="transition ease-in-out delay-80 hover:-translate-y-1 hover:scale-105 duration-300 border-2 border-slate-900 rounded-3xl shadow-lg shadow-indigo-500/50 drop-shadow-md hover:shadow-white hover:drop-shadow-x">
              <ul>
                <strong>
                  7N 8D 2N Munnar + 1 Thekkady + 1N Alleppey Houseboat + 2N
                  Kovalam +1N Kanyakunari
                </strong>
                <li>2 pax Rs. 31,700/- per head</li>
                <li>3 pax Rs. 26,000/- per head</li>
                <li>4 pax Rs. 24,500/- per head</li>
                <li>5 pax Rs. 24,350/- per head</li>
                <li>6 pax Rs. 23,750/- per head</li>
              </ul>
            </div>
          </div>

          <figure>
            <img
              src="https://keralatour.net/packageimg/1528541248_Explore-Southern-India-with-Sri-Lanka.jpg"
              alt="Blog Page"
              className="rounded-2xl"
            />
          </figure>

          <h3>Hotels 3 star Standard</h3>

          <ul>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Kochi Pearl
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Millennium Continental Deluxe AC
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Avenue Plaza Premium AC Munnar Green Cove
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              At Woods
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Casa Amari
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Horizon Heights Deluxe Non AC Thekkady Patio
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Spice Residency
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Hostess Deluxe Non AC
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Seasons Classic Non AC Alleppey 1N AC Deluxe Houseboat Shared
              Kovalam Jeevan Beach Resort Deluxe AC
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Hawa Beach Standard AC Kanyakumari Temple Citi Deluxe AC
            </li>
            <li className="text-black hover:text-red-900 hover:font-bold">
              Gopinivas Grand Superior AC
            </li>
          </ul>
        </div>
        <div className="container max-w-xl mx-auto">
          <h1 className="text-red-800 font-semibold text-xl md:text-xl md:!leading-[120%] lg:text-xl dark:text-neutral-100 max-w-4xl">
            Send Enquiry Regarding the packgage {packageRequested}
          </h1>
          <form
            className="grid grid-cols-1 gap-6"
            method="post"
            onSubmit={(e: any) => handleSubmit(e)}
          >
            <label className="block">
              <Label>Full name</Label>

              <Input
                placeholder="Example Doe"
                name="name"
                type="text"
                value={initialValues.name}
                className="mt-1"
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Email address</Label>

              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                value={initialValues.email}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Message</Label>
              <Textarea
                className="mt-1 focus:text-white"
                rows={6}
                name="message"
                value={initialValues.message}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <div>
              <ButtonPrimary type="submit">Send Message</ButtonPrimary>
            </div>
          </form>
        </div>
      </>
    );
  };

  //   useEffect(() => {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const packageRequested = urlParams.get("package");
  //     if (packageRequested) {
  //       setPackageRequested(packageRequested);
  //       initialValues.packageRequested = packageRequested;
  //       setInitialValues({
  //         ...initialValues,
  //         packageRequested: packageRequested,
  //       });
  //     }
  //   }, []);

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Solitary Packages</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.squarespace-cdn.com/content/v1/5eb8e2907c57723ce63891ca/1592542293201-3DYCYR34WPQ80K5R3AB7/kerala+Backwaters1.jpg"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  );
};

export default KeralaPackage;
