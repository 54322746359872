import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFilterCard from "containers/ListingStayPage/SectionGridFilterCard";
import React, { FC, useState, CSSProperties, useEffect } from "react";
import { Helmet } from "react-helmet";
import Heading2 from "components/Heading/Heading2";
import ClipLoader from "react-spinners/ClipLoader";
import Card from './../../components/Packages/Card';
import { axiosInstance } from "index";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#68391B",
};

export interface HotelListingProps {
  className?: string;
}
const PackageListing = ({ className = "" }) => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);


  const getPackageDestinations=async()=>{
    setLoading(true);
    try {
      const response=await axiosInstance.get("/package-dest/names/only");
      console.log(response)
      if(response.status===200){
        setPackages(response.data.data)
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getPackageDestinations();
  },[])

  return (
    <div
      className={`nc-HotelListing relative overflow-hidden ${className}`}
      data-nc-id="HotelListing"
    >
      <Helmet>
        <title>Packages || Solitary Holydays</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION */}

        <Heading2 heading="Packages" className="mt-12"/>

        <div
          className={`nc-SectionGridFilterCard ${className}`}
          data-nc-id="SectionGridFilterCard"
        >

          <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
          {!loading ? (
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {packages.map((stay, index) => (
                <Card key={index} data={stay} type="grid" />
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <ClipLoader
                color={"#68391B"}
                loading={loading}
                cssOverride={override}
                size={250}
              />
            </div>
          )}
        </div>

        {/* SECTION */}

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />
      </div>
    </div>
  );
};

export default PackageListing;
