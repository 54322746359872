import Heading from "components/Heading/Heading";
import Heading2 from "components/Heading/Heading2";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PackageCard from "./PackageCard";
import Card from './Card';
import { axiosInstance } from "index";

const PackageGrid = () => {
  const [destination, setDestination] = useState("");
  const [packageItem, setPackageItem] = useState<any>();
  const [packages,setPackages]=useState([]);

  const getPackagesBySlug = async () => {
    try {
      const response = await axiosInstance.get(
        "/package-dest/slug/" + destination
      );
      console.log(response)
      if (response.status === 200) {
        console.log(response.data.data.packages);
        setPackageItem((prev:any) => {
          return response.data.data;
        });

        setPackages((prev)=>{
            return response.data.data.packages
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const destination = urlParams.get("destination");
    console.log(destination);
    if (destination) {
      setDestination(destination);
    }
  }, []);

  useEffect(() => {
    if (
      destination !== " " &&
      destination !== undefined &&
      destination !== null
    ) {
      getPackagesBySlug();
    }
  }, [destination]);

  useEffect(()=>{
    console.log(packages);
  },[packages])

  return (
    <div className={`nc-HotelListing relative overflow-hidden`}>
      <Helmet>
        <title>Packages || Solitary Holydays</title>
      </Helmet>

      <div className="container relative overflow-hidden mt-5">
        <Heading2 heading={`${destination} Packages`} />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center gap-6 mb-10">
          {/* {Array.from({ length: 7 }).map((_, index) => (
            <PackageCard />
          ))} */}
          {
            packages && packages.map((item:any)=>{
                // return <PackageCard {...item} />
                return <Card data={item} />
            })
          }
        </div>
      </div>
    </div>
  );
};

export default PackageGrid;
