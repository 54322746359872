import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import { axiosInstance } from "index";
import React,{useEffect, useState} from "react";


export interface SectionGridCategoryBoxProps {
  categories?: any[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const DEMO_CATS: any = [
  {
    id: "1",
    href: "/blog-single/ladakh-package",
    path:"",
    name: "Ladakh",
    title: "Ladakh",
    city: "Goa",
    featuredPropertyMedia: { path: "" },
    taxonomy: "category",
    count: 1882,
    thumbnail:
      "https://images.unsplash.com/photo-1414609245224-afa02bfb3fda?q=80&w=1889&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: "2",
    href: "/blog-single/kerala-package",
    path:"",
    name: "Kerala",
    title: "Kerala",
    featuredPropertyMedia: { path: "" },
    taxonomy: "category",
    city: "Goa",
    count: 8288,
    thumbnail:
      "https://images.unsplash.com/photo-1582972236019-ea4af5ffe587?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: "3",
    href: "/blog-single/manali-package",
    path:"",
    name: "Manali",
    title: "Hyderabad",
    city: "Goa",
    featuredPropertyMedia: { path: "" },
    taxonomy: "category",
    count: 1288,
    thumbnail:
      "https://imgs.search.brave.com/sfpTUajmT8dfI4j1DKjJGdECj2_ay0hxKIzMat3fPg8/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90aHVt/YnMuZHJlYW1zdGlt/ZS5jb20vYi9hZ29k/YS1mb3J0LWJlYWNo/LWdvYS1uaWNlLXZp/ZXctdG8taGF2ZS1n/bGltcHNlLXNlYS0x/ODAzOTIyMzkuanBn",
  },
  {
    id: "4",
    href: "#",
    name: "More",
    title: "More..Coming Soon",
    path:"",
    city: "Goa",
    featuredPropertyMedia: { path: "" },
    taxonomy: "category",
    count: 0,
    thumbnail:
      "https://imgs.search.brave.com/1Lx2MY-ZQV9wM5twWQIg00hmnh_zTfgWAZHcT2_V5UQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5nZXR0eWltYWdl/cy5jb20vaWQvOTk1/NDk4MTUyL3Bob3Rv/L2xpdHRsZS12YWdh/dG9yLXN1bnNldC5q/cGc_cz02MTJ4NjEy/Jnc9MCZrPTIwJmM9/U2VieHhzRTU3c05C/eXdtd3B5WVhXU0Ew/NTh0SktyY253N3Ax/YnB2enJ0WT0 ",
  },
];

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories = DEMO_CATS,
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  const [packageDestinations,setPackageDestinations]=useState([]);



  const getPackageDestinations=async()=>{
    try {
      const response=await axiosInstance.get("/package-dest/names/only");
      console.log(response)
      if(response.status===200){
        setPackageDestinations(response.data.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getPackageDestinations();
  },[])

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading
        desc="Discover great packages"
        isCenter={headingCenter}
      >
        Explore our Packages
      </Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {packageDestinations.map((item, i) => (
          <CardComponentName key={i} taxonomy={item} />
        ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
