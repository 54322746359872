import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface StayCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
  type?: string;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  type = "view",
  data,
}) => {
  const { path, name, slug, id } = data;
  console.log(data);
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        {type === "grid" ? (
          <GallerySlider
            type={0}
            uniqueID={`StayCard_${id}`}
            ratioClass="aspect-w-4 aspect-h-3 "
            galleryImgs={[path]}
            // galleryImgs={propertyMedia.map((item) => item.path)}
            href={`/package-grid?destination=${slug}`}
          />
        ) : (
          <GallerySlider
            type={0}
            uniqueID={`StayCard_${id}`}
            ratioClass="aspect-w-4 aspect-h-3 "
            galleryImgs={[path]}
            // galleryImgs={propertyMedia.map((item) => item.path)}
            href={`/package-single?package=${slug}`}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div>
        <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <h2
                className={` font-medium capitalize ${
                  size === "default" ? "text-lg" : "text-base"
                }`}
              >
                <span className="line-clamp-1">{name}</span>
              </h2>
            </div>
          </div>
          <div className="flex justify-between items-center">
            {type === "grid" && (
              <span className="text-base font-semibold text-brown">
                View Packages
              </span>
            )}
            {type === "view" && (
              <span className="text-base font-semibold text-brown">
                Starting@{data.price}
              </span>
            )}
            <div>
              {data.days && (
                <span className="text-base text-black text-sm">
                  {data.days}
                  {""}D
                </span>
              )}
              {data.nights && (
                <span className="text-base text-black text-sm">
                  {"/"}
                  {data.nights}
                  {""}N
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          className="w-full bg-brown text-center"
          style={{ width: "100% !important" }}
        >
          {type === "view" && data.offer && (
            <span
              className="text-white text-sm"
              style={{ width: "100% !important" }}
            >
              {data.offer}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900  rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {
        type === "grid" ? <Link to={`/package-grid?destination=${slug}`}>{renderContent()}</Link> :<Link to={`/package-single?package=${slug}`}>{renderContent()}</Link>
      }
    </div>
  );
};

export default StayCard;
