import { FC, useEffect, useState, CSSProperties } from "react";
import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import { axiosInstance } from "../../index";
import { useSearch } from "SearchContext";
import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#68391B",
};

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

export interface Price{
  from:string;
  to:string;
  price:number;
}
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const [properties, setProperties] = useState([]);
  const [loading,setLoading]=useState(false);
  const { type, location, city } = useSearch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(64);
  const [total, setTotal] = useState<number>(0);
  const handlePageChange = async (selectedPage: number) => {
    setPageNumber(selectedPage);
  };

  function getLowestPrice(seasonPrice: Price[]): number {
    if (seasonPrice.length === 0) {
      return 0;
    }
    let lowestPrice = seasonPrice[0].price;
    for (let i = 1; i < seasonPrice.length; i++) {
      if (seasonPrice[i].price < lowestPrice) {
        lowestPrice = seasonPrice[i].price;
      }
    }

    return lowestPrice;
  }
  const getPropertiesData = async () => {
    try {
      setLoading(true);
      const queryParams: any = {
        pageSize: 64,
        page: pageNumber,
      };

      if (city !== "") {
        queryParams.city = city;
      }
      if (location !== "") {
        queryParams.locality = location;
      }

      const response = await axiosInstance.get("/users/hotels", {
        params: queryParams,
      });

      if (response.status === 200) {
        const data = response.data.data
          .filter((ele: any) => ele.room.length > 0)
          .map((item: any) => {
            let minPrice = Infinity;
            item.room.forEach((room: any) => {
              // console.log(room.price,"from grid")
              if (room.price) {
                const roomMinPrice = getLowestPrice(room.price);
                minPrice = Math.min(minPrice, roomMinPrice);
              }
            });
            return { ...item, price: minPrice };
          })
          .filter((item: any) => {
            if (type === "All") {
              return true;
            } else {
              return item.property_typeId === (type === "Hotels" ? 1 : 2);
            }
          });
        setProperties(data);
        setTotal(response.data.totalPages);
      }
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    getPropertiesData();
  }, [pageNumber, location, type, city]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 />

      <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
      {!loading ? (
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {properties.map((stay, index) => (
            <StayCard key={index} data={stay} />
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <ClipLoader
            color={"#68391B"}
            loading={loading}
            cssOverride={override}
            size={250}
          />
        </div>
      )}
      <div className="flex mt-16 justify-center items-center">
        {/* <Pagination /> */}
        <Pagination total={total} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
