import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Swal from "sweetalert2";
import { axiosInstance } from "index";
import { repeat } from "lodash";
import { fr } from "@faker-js/faker";

export interface BlogSinglePageProps {
  className?: string;
  header?: string;
  content?: string;
}

const ManaliPackage: FC<BlogSinglePageProps> = ({
  className = "",
  header,
  content,
}) => {
  const [packageRequested, setPackageRequested] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    message: "",
    packageRequested: "Ladakh",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value);
    setInitialValues({ ...initialValues, [name]: value });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/support/", initialValues);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Message Sent",
          text: "We will get back to you soon",
          showConfirmButton: true,
          confirmButtonColor: "#A47557",
          // timer: 800,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          confirmButtonColor: "#A47557",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="blue" name="Traveler" type="package" icon="Mountain"/>
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            3N Manali-Ex-Chandigarh{" "}
          </h1>
          <div>
            <h6 className="hover:translate-x-1.5 delay-90">
              🍛Breakfast and Dinner🍱
            </h6>
            <h6 className="hover:translate-x-1.5">⛴Alleppey AC Deluxe </h6>
            <h6 className="hover:translate-x-1.5">⛵House boat stay🚢 </h6>
            <h6 className="hover:translate-x-1.5">🧍All prices per person💃</h6>
          </div>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <>
        <h3>
          <strong style={{ color: "#68391B", fontSize: "30px" }}>
            3N Manali-Ex-Chandigarh{" "}
          </strong>
        </h3>
        <div>
          <div className="mt-5 mb-20 pt-5 pb-5 max-w-full overflow-auto">
            <table className="border-collapse border border-slate-500">
              <thead>
                <tr className="border border-slate-600">
                  <th className="border border-slate-600">Place</th>
                  <th className="border border-slate-600">Meal</th>
                  <th className="border border-slate-600">Night</th>
                  <th className="border border-slate-600">Deluxe</th>
                  <th className="border border-slate-600">Super Deluxe</th>
                  <th className="border border-slate-600">Luxury</th>
                  <th className="border border-slate-600">Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-slate-600">
                  <th className="border border-slate-600">Manali</th>
                  <th className="border border-slate-600">MAP</th>
                  <th className="border border-slate-600">3</th>
                  <th className="border border-slate-600">
                    Pristine Inn / Swastik Grand
                  </th>
                  <th className="border border-slate-600">
                    Olive Country Resort
                  </th>
                  <th className="border border-slate-600">
                    Coral Resort And Spa / Mastiff Grand Manali Resort
                  </th>
                  <th className="border border-slate-600">
                    ManuAllaya Resort spa / Tiaraa Hotels &amp; Resorts
                  </th>
                </tr>

                <tr className="border border-slate-600">
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">11750</td>
                  <td className="border border-slate-600">12500</td>
                  <td className="border border-slate-600">15050</td>
                  <td className="border border-slate-600">19550</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">15500</td>
                  <td className="border border-slate-600">16250</td>
                  <td className="border border-slate-600">18800</td>
                  <td className="border border-slate-600">23300</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">
                    Per Person min. 6 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">13200</td>
                  <td className="border border-slate-600">13900</td>
                  <td className="border border-slate-600">16500</td>
                  <td className="border border-slate-600">21000</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">
                    Per Person min. 08 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">14875</td>
                  <td className="border border-slate-600">15625</td>
                  <td className="border border-slate-600">18175</td>
                  <td className="border border-slate-600">22675</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">
                    Per Person min. 10 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">13500</td>
                  <td className="border border-slate-600">14250</td>
                  <td className="border border-slate-600">16800</td>
                  <td className="border border-slate-600">21300</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">
                    Per Person min. 12 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">12585</td>
                  <td className="border border-slate-600">13335</td>
                  <td className="border border-slate-600">15885</td>
                  <td className="border border-slate-600">20385</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">12 Year + Adult</td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">5200</td>
                  <td className="border border-slate-600">5725</td>
                  <td className="border border-slate-600">7510</td>
                  <td className="border border-slate-600">14800</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">
                    Child 6 to 12 Years
                  </td>

                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">4600</td>
                  <td className="border border-slate-600">5050</td>
                  <td className="border border-slate-600">6580</td>
                  <td className="border border-slate-600">13500</td>
                </tr>
              </tbody>
            </table>

            <div>
              <h1 className="text-black-900 font-bold">Package Inclusion</h1>
              <ol>
                <li>
                  Pickup from Chandigarh and drop at Chandigarh Railway station
                  By Dzire/ SUV / 12 Seater Tempo.
                </li>
                <li>Welcome Drinks on arrival at the hotel.</li>
                <li>
                  03 Nights / 04 Days Accommodation in well-appointed Room.
                </li>
                <li>03 Breakfasts and 03 Dinner at hotel.</li>
                <li>Rates are including of GST.</li>
              </ol>
              <ol>
                <li>
                  <strong>
                    Day 1: ARRIVAL AT CHANDIGARH & PROCEED TO MANALI (9HRS.)
                  </strong>
                  <p>
                    Arrival at Chandigarh railway station later proceeds for
                    Manali by road another beautiful hill station in the state.
                    Located in the Beas River Valley, at an elevation of more
                    than 2000 meters above sea level, Manali can be reached from
                    the national highway that leads to the Ladakh region. Manali
                    is about 40 km from another scenic hill resort – Kullu. Home
                    to snow-clad mountain peaks and rich flora and fauna, the
                    town of Manali is flocked by tourists from across the
                    country, especially in the holiday season. The name 'Manali'
                    has been derived from the words 'Manu' and 'Alaya', which
                    mean 'Abode of Manu'. According to a legend, Manu was a sage
                    who is believed to have recreated human life after the world
                    was devastated by a great flood. There is an ancient temple
                    in the old Manali village that is devoted to this saga.
                    Besides this, some other prominent attractions of this hill
                    town are Hadimba Temple, Manali Sanctuary, Arjun Gufa and
                    Jagatsukh. Upon reaching, check-in at the hotel and take
                    some rest. Dinner at Hotel. Overnight at Manali.
                  </p>
                </li>
                <li>
                  <strong>
                    Day 2: MANALI - LOCAL SIGHT SEEING & SOLANG VALLEY VISIT
                  </strong>
                  <p>
                    After breakfast get set to visit the popular tourist
                    attractions of Manali. Morning we proceed for Solang Valley
                    where we can enjoy adventure activities like Zorbing,
                    Paragliding. Later you can also visit Tibetan Monastery- not
                    only a place of worship but also a Tibetan culture centre,
                    the natural springs and sandstone temples at Vashist
                    Village, Hadimba Devi Temple- dedicated to Hidimba, wife of
                    Bhim-one of the 5 Pandava brother of the Hindu epic
                    Mahabharata and the Manu Temple- an old temple dedicated to
                    sage Manu. Club House In evening we return back to hotel or
                    free time at mall road. Overnight at Manali.
                  </p>
                </li>
                <li>
                  <strong>
                    Day 3: MANALI - ROHTANG PASS EXCURSION (50KMS) - AT OWN COST
                  </strong>
                  <p>
                    After early breakfast enjoy a breathtaking drive to Snow
                    Point on the road to Rohtang Pass (Closed On Tuesdays).
                    Known for its scenic beauty Rohtang Pass holds strategic
                    importance for India. Manali-Leh Highway a part of NH 21
                    transverses Rohtang Pass. The name Rohtang means ‘ground of
                    corpses’ due to the number of people who lost their lives
                    trying to cross it. Besides Lahaul and Spiti Rohtang Pass
                    also serves as the gateway to Pangi and the valley of Leh.
                    The Pass offers beautiful sights of glaciers peaks Lahaul
                    Valley and the Chandra River. The twin peaks of Geypan are
                    also visible from Rohtang. Later visit the famous Solang
                    Valley. Solang Valley locally known as Solang Nullah is
                    renowned as the preferred location for adventure activities
                    in Himachal Pradesh. For those who love the rush of
                    adrenaline; there is no place better than Solang in Kullu
                    Valley to indulge in parachuting paragliding skating and
                    zorbing. It is 8500 feet above mean sea level. Solang is not
                    just about adventure activities. Its lush greenery apple
                    orchards snowcapped peaks and lakes attract lacs of
                    visitors. Return to hotel by the evening. Enjoy Dinner and
                    overnight in hotel.
                  </p>
                </li>
                <li>
                  <strong>Day 4: MANALI TO CHANDIGARH DROP (9HRS.)</strong>
                  <p>
                    After breakfast we checkout from hotel and proceed for
                    Chandigarh by road. On arrival you will be transferred to
                    the airport/railway station to board the flight/Train back
                    to home.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <figure>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/0/03/Manali_City.jpg"
              alt="Blog Page"
              className="rounded-2xl"
            />
          </figure>

          <div className="mt-10 mb-20 pt-5 pb-5 max-w-full overflow-auto">
            <h1 className="d-flex justify-center mb-10 mt-10">
              <strong style={{ color: "#68391B", fontSize: "30px" }}>
                3N Manali-1 Chandigarh, Ex-Chandigarh{" "}
              </strong>
            </h1>
            <table className="border-collapse border border-slate-500">
              <thead>
                <tr className="border border-slate-600 text-red-900">
                  <th className="border border-slate-600">Place</th>
                  <th className="border border-slate-600">Meal</th>
                  <th className="border border-slate-600">Night</th>
                  <th className="border border-slate-600">Deluxe</th>
                  <th className="border border-slate-600">Super Deluxe</th>
                  <th className="border border-slate-600">Luxury</th>
                  <th className="border border-slate-600">Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Manali</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">3</td>
                  <td className="border border-slate-600">
                    Pristine Inn / Swastik Grand
                  </td>
                  <td className="border border-slate-600">
                    Olive Country Resort
                  </td>
                  <td className="border border-slate-600">
                    Foxoso Coral Resort And Spa / Mastiff Grand Manali Resort
                  </td>
                  <td className="border border-slate-600">
                    ManuAllaya Resort spa / Tiaraa Hotels & Resorts
                  </td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Chandigarh</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">1</td>
                  <td className="border border-slate-600">Shagun</td>
                  <td className="border border-slate-600">Royal Crown</td>
                  <td className="border border-slate-600">
                    Velvet Clarks Exotica
                  </td>
                  <td className="border border-slate-600">Best western</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 2 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">16000</td>
                  <td className="border border-slate-600">16500</td>
                  <td className="border border-slate-600">1900</td>
                  <td className="border border-slate-600">23700</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">11150</td>
                  <td className="border border-slate-600">11750</td>
                  <td className="border border-slate-600">15450</td>
                  <td className="border border-slate-600">19950</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">15000</td>
                  <td className="border border-slate-600">15500</td>
                  <td className="border border-slate-600">19200</td>
                  <td className="border border-slate-600">23700</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 6 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">12500</td>
                  <td className="border border-slate-600">13100</td>
                  <td className="border border-slate-600">16800</td>
                  <td className="border border-slate-600">21300</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 8 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">14275</td>
                  <td className="border border-slate-600">14875</td>
                  <td className="border border-slate-600">18575</td>
                  <td className="border border-slate-600">23075</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 10 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">12900</td>
                  <td className="border border-slate-600">13500</td>
                  <td className="border border-slate-600">17200</td>
                  <td className="border border-slate-600">21700</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 12 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">11985</td>
                  <td className="border border-slate-600">12585</td>
                  <td className="border border-slate-600">16285</td>
                  <td className="border border-slate-600">20785</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">12 Year + Adult</td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">5200</td>
                  <td className="border border-slate-600">5600</td>
                  <td className="border border-slate-600">8200</td>
                  <td className="border border-slate-600">14700</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Child 6 to 12 Years
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">4450</td>
                  <td className="border border-slate-600">4800</td>
                  <td className="border border-slate-600">7020</td>
                  <td className="border border-slate-600">13000</td>
                </tr>
              </tbody>
            </table>
            <h1>Package Inclusions</h1>
            <ol>
              <li>
                Pickup from Chandigarh and drop at Chandigarh Railway station By
                Dzire/ SUV / 12 Seater Tempo.
              </li>
              <li>Welcome Drinks on arrival at the hotel.</li>
              <li>04 Nights / 05 Days Accommodation in well-appointed Room.</li>
              <li>04 Breakfasts and 04 Dinner at hotel.</li>
              <li>Rates are including of GST.</li>
            </ol>
            <ol>
              <li>
                <strong>
                  Day 1: ARRIVAL AT CHANDIGARH & PROCEED TO MANALI (9HRS.)
                </strong>
                <p>
                  Arrival at Chandigarh railway station later proceeds for
                  Manali by road another beautiful hill station in the state.
                  Located in the Beas River Valley, at an elevation of more than
                  2000 meters above sea level, Manali can be reached from the
                  national highway that leads to the Ladakh region. Manali is
                  about 40 km from another scenic hill resort – Kullu. Home to
                  snow-clad mountain peaks and rich flora and fauna, the town of
                  Manali is flocked by tourists from across the country,
                  especially in the holiday season. The name 'Manali' has been
                  derived from the words 'Manu' and 'Alaya', which mean 'Abode
                  of Manu'. According to a legend, Manu was a sage who is
                  believed to have recreated human life after the world was
                  devastated by a great flood. There is an ancient temple in the
                  old Manali village that is devoted to this saga. Besides this,
                  some other prominent attractions of this hill town are Hadimba
                  Temple, Manali Sanctuary, Arjun Gufa, and Jagatsukh. Upon
                  reaching, check-in at the hotel and take some rest. Dinner at
                  Hotel. Overnight at Manali.
                </p>
              </li>
              <li>
                <strong>
                  Day 2: MANALI - LOCAL SIGHT SEEING & SOLANG VALLEY VISIT
                </strong>
                <p>
                  After breakfast get set to visit the popular tourist
                  attractions of Manali. Morning we proceed for Solang Valley
                  where we can enjoy adventure activities like Zorbing,
                  Paragliding. Later you can also visit Tibetan Monastery- not
                  only a place of worship but also a Tibetan culture center, the
                  natural springs and sandstone temples at Vashist Village,
                  Hadimba Devi Temple- dedicated to Hidimba, wife of Bhim-one of
                  the 5 Pandava brother of the Hindu epic Mahabharata and the
                  Manu Temple- an old temple dedicated to sage Manu. Club House
                  In the evening we return back to the hotel or free time at the
                  mall road. Overnight at Manali.
                </p>
              </li>
              <li>
                <strong>
                  Day 3: MANALI - ROHTANG PASS EXCURSION (50KMS) - AT OWN COST
                </strong>
                <p>
                  After early breakfast enjoy a breathtaking drive to Snow Point
                  on the road to Rohtang Pass (Closed On Tuesdays). Known for
                  its scenic beauty Rohtang Pass holds strategic importance for
                  India. Manali-Leh Highway a part of NH 21 transverses Rohtang
                  Pass. The name Rohtang means ‘ground of corpses’ due to the
                  number of people who lost their lives trying to cross it.
                  Besides Lahaul and Spiti Rohtang Pass also serves as the
                  gateway to Pangi and the valley of Leh. The Pass offers
                  beautiful sights of glaciers peaks Lahaul Valley and the
                  Chandra River. The twin peaks of Geypan are also visible from
                  Rohtang. Later visit the famous Solang Valley. Solang Valley
                  locally known as Solang Nullah is renowned as the preferred
                  location for adventure activities in Himachal Pradesh. For
                  those who love the rush of adrenaline; there is no place
                  better than Solang in Kullu Valley to indulge in parachuting
                  paragliding skating and zorbing. It is 8500 feet above mean
                  sea level. Solang is not just about adventure activities. Its
                  lush greenery apple orchards snowcapped peaks and lakes
                  attract lacs of visitors. Return to the hotel by the evening.
                  Enjoy Dinner and overnight in the hotel.
                </p>
              </li>
              <li>
                <strong>Day 4: MANALI TO CHANDIGARH (09HRS)</strong>
                <p>
                  After breakfast, complete the check-out formalities at the
                  hotel and then proceed for Chandigarh. Chandigarh - is the
                  first planned modern city of India designed by the French
                  architect Le Corbusier. Chandigarh is a city in India that
                  serves as the capital of two states - Punjab and Haryana.
                  However, the city does not belong to either state. Rather, the
                  city is administered by the federal government and hence
                  classified as a union territory.
                </p>
              </li>
              <li>
                <strong>
                  Day 5: CHANDIGARH HOTEL TO CHANDIGARH AIRPORT | STATION DROP
                </strong>
                <p>
                  After breakfast, we check out from the hotel and proceed for
                  Chandigarh by road. On arrival, you will be transferred to the
                  airport/railway station to board the flight/Train back home.
                </p>
              </li>
            </ol>
          </div>
          <div className="mt-10 mb-20 pt-5 pb-5 max-w-full overflow-auto">
            <h1 className="mt-10 mb-10">
              <strong style={{ color: "#68391B", fontSize: "30px" }}>
                2N Shimla-3N Manali, Ex-Chandigarh
              </strong>
            </h1>
            <table className="border-collapse border border-slate-500">
              <thead>
                <tr className="border border-slate-600">
                  <th className="border border-slate-600">Place</th>
                  <th className="border border-slate-600">Meal</th>
                  <th className="border border-slate-600">Night</th>
                  <th className="border border-slate-600">Deluxe</th>
                  <th className="border border-slate-600">Super Deluxe</th>
                  <th className="border border-slate-600">Luxury</th>
                  <th className="border border-slate-600">Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">SHIMLA</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">2</td>
                  <td className="border border-slate-600">Katson Blue</td>
                  <td className="border border-slate-600">
                    The Royal Regency/CK international
                  </td>
                  <td className="border border-slate-600">
                    Kufri Pacific / Agate Pandora
                  </td>
                  <td className="border border-slate-600">
                    The Orchid Hotel Shimla
                  </td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Manali</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">3</td>
                  <td className="border border-slate-600">
                    Pristine Inn / Swastik Grand
                  </td>
                  <td className="border border-slate-600">
                    Olive Country Resort/ Sandhya Resort & Spa
                  </td>
                  <td className="border border-slate-600">
                    Foxoso Coral Resort And Spa / Mastiff Grand Manali Resort
                  </td>
                  <td className="border border-slate-600">
                    ManuAllaya Resort spa / Tiaraa Hotels & Resorts
                  </td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 2 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">18750</td>
                  <td className="border border-slate-600">20200</td>
                  <td className="border border-slate-600">24050</td>
                  <td className="border border-slate-600">31750</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">14375</td>
                  <td className="border border-slate-600">15725</td>
                  <td className="border border-slate-600">19675</td>
                  <td className="border border-slate-600">27375</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">18000</td>
                  <td className="border border-slate-600">19500</td>
                  <td className="border border-slate-600">23300</td>
                  <td className="border border-slate-600">31000</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 6 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">15500</td>
                  <td className="border border-slate-600">16700</td>
                  <td className="border border-slate-600">20650</td>
                  <td className="border border-slate-600">28350</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 8 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">16875</td>
                  <td className="border border-slate-600">18225</td>
                  <td className="border border-slate-600">22175</td>
                  <td className="border border-slate-600">29875</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 10 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">15500</td>
                  <td className="border border-slate-600">16850</td>
                  <td className="border border-slate-600">20800</td>
                  <td className="border border-slate-600">28500</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 12 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">14583</td>
                  <td className="border border-slate-600">15950</td>
                  <td className="border border-slate-600">19900</td>
                  <td className="border border-slate-600">27585</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">12 Year + Adult</td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">7000</td>
                  <td className="border border-slate-600">7950</td>
                  <td className="border border-slate-600">10710</td>
                  <td className="border border-slate-600">16500</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Child 6 to 12 Years
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">6000</td>
                  <td className="border border-slate-600">6800</td>
                  <td className="border border-slate-600">9200</td>
                  <td className="border border-slate-600">14000</td>
                </tr>
              </tbody>
            </table>
            <h1>Package Inclusions</h1>
            <ol>
              <li>
                Pickup from Chandigarh and drop at Chandigarh Railway station By
                Dzire/ SUV / 12 Seater Tempo.
              </li>
              <li>Welcome Drinks on arrival at the hotel.</li>
              <li>05 Nights / 06 Days Accommodation in well-appointed Room.</li>
              <li>05 Breakfasts and 05 Dinner at hotel.</li>
              <li>Rates are including of GST.</li>
            </ol>
            <ol>
              <li>
                <strong>
                  Day 1 - ARRIVE CHANDIGARH AND DRIVE TO SHIMLA (6HRS)
                </strong>
                <p>
                  After arriving at the Chandigarh airport/ railway station, our
                  tour representative meets you and helps you with an assisted
                  transfer to Shimla, by road. After reaching Shimla, check-in
                  at the hotel. Spend the evening as per your own liking. Fondly
                  referred to as the “Queen of Hills’ Shimla is the capital city
                  of Himachal Pradesh, which boasts of mesmerizing scenery and a
                  laid-back atmosphere. During your leisure time, explore the
                  nearby places on your own such as visiting the Mall, the Ridge
                  and so on. Stay overnight.
                </p>
              </li>
              <li>
                <strong>
                  Day 2 - SHIMLA - KUFRI EXCURSION WITH LOCAL SIGHT SEEING
                </strong>
                <p>
                  Today morning after a filling delicious breakfast visit Kufri.
                  Kufri derives its name from ‘Kufr’ which means lake. It is
                  especially popular among trekkers. The salubrious climate and
                  stunning natural beauty makes Kufri a perfect summer getaway.
                  Kufri is the venue for annual winter sports festival held in
                  February. Kufri is also renowned for other adventures
                  activities such as skiing tobogganing (sliding downhill on a
                  sled) etc. Kufri has the oldest skiing slopes in Himachal
                  Pradesh. Kufri is also a haven for nature enthusiasts and
                  wildlife lovers as the Himalayan Nature Park is situated on
                  the outskirts of the town. The Park is home to over 180
                  varieties of fauna. Thereafter visit the famous mall road of
                  Shimla. Visit the Scandal Point Jhakoo Temple (private
                  vehicles do not have access to Jhakoo temple because of the
                  location. The guests can take a shared taxi available on Mall
                  Road and visit the temple. It’s advisable to visit the temple
                  before it turns dark; hence the guests can first visit the
                  temple and then spend time on the mall road.) After ample of
                  Shopping time return to your hotel for a mouth watering
                  dinner. Overnight in the resort.
                </p>
              </li>
              <li>
                <strong>Day 3 - SHIMLA TO MANALI (9HRS)</strong>
                <p>
                  Have your breakfast and begin driving Manali, one of the most
                  popular hill stations of India. Upon arriving, complete the
                  check- in formalities at the hotel. The evening is at leisure.
                  Flocked by honeymooners and family vacationers all around the
                  year, Manali is dotted with a number of attractions. Stay
                  overnight.
                </p>
              </li>
              <li>
                <strong>
                  Day 4 - MANALI - LOCAL SIGHT SEEING & SOLANG VALLEY VISIT
                </strong>
                <p>
                  After breakfast get set to visit the popular tourist
                  attractions of Manali. Morning we proceed for Solang Valley
                  where we can enjoy adventure activities like Zorbing,
                  Paragliding. Later you can also visit Tibetan Monastery- not
                  only a place of worship but also a Tibetan culture centre, the
                  natural springs and sandstone temples at Vashist Village,
                  Hadimba Devi Temple- dedicated to Hidimba, wife of Bhim-one of
                  the 5 Pandava brother of the Hindu epic Mahabharata and the
                  Manu Temple- an old temple dedicated to sage Manu. Club House
                  In evening we return back to hotel or free time at mall road.
                  Overnight at Manali.
                </p>
              </li>
              <li>
                <strong>
                  Day 5 - MANALI - ROHTANG PASS EXCURSION (50KMS) - AT OWN COST
                </strong>
                <p>
                  After early breakfast enjoy a breathtaking drive to Snow Point
                  on the road to Rohtang Pass (Closed On Tuesdays). Known for
                  its scenic beauty Rohtang Pass holds strategic importance for
                  India. Manali-Leh Highway a part of NH 21 transverses Rohtang
                  Pass. The name Rohtang means ‘ground of corpses’ due to the
                  number of people who lost their lives trying to cross it.
                  Besides Lahaul and Spiti Rohtang Pass also serves as the
                  gateway to Pangi and the valley of Leh. The Pass offers
                  beautiful sights of glaciers peaks Lahaul Valley and the
                  Chandra River. The twin peaks of Geypan are also visible from
                  Rohtang. Later visit the famous Solang Valley. Solang Valley
                  locally known as Solang Nullah is renowned as the preferred
                  location for adventure activities in Himachal Pradesh. For
                  those who love the rush of adrenaline; there is no place
                  better than Solang in Kullu Valley to indulge in parachuting
                  paragliding skating and zorbing. It is 8500 feet above mean
                  sea level. Solang is not just about adventure activities. Its
                  lush greenery apple orchards snowcapped peaks and lakes
                  attract lacs of visitors. Return to hotel by the evening.
                  Enjoy Dinner and overnight in hotel.
                </p>
              </li>
              <li>
                <strong>
                  Day 6 - MANALI TO CHANDIGARH AIRPORT OR STATION DROP
                </strong>
                <p>
                  After breakfast we checkout from hotel and proceed for
                  Chandigarh airport/railway station to board the flight/Train
                  back to home.
                </p>
              </li>
            </ol>
          </div>
          <div className="mt-10 mb-20 pt-5 pb-5 max-w-full overflow-auto">
            <h1 className="mt-10 mb-10">
              <strong style={{ color: "#68391B", fontSize: "30px" }}>
                2N Shimla-3N Manali-1N Chandigarh, EX-Chandigarh
              </strong>
            </h1>
            <table className="border-collapse border border-slate-500">
              <thead>
                <tr className="border border-slate-600">
                  <th className="border border-slate-600">Place</th>
                  <th className="border border-slate-600">Meal</th>
                  <th className="border border-slate-600">Night</th>
                  <th className="border border-slate-600">Deluxe</th>
                  <th className="border border-slate-600">Super Deluxe</th>
                  <th className="border border-slate-600">Luxury</th>
                  <th className="border border-slate-600">Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">SHIMLA</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">2</td>
                  <td className="border border-slate-600">Katson Blue</td>
                  <td className="border border-slate-600">
                    The Royal Regency/CK international
                  </td>
                  <td className="border border-slate-600">
                    Kufri Pacific / Agate Pandora
                  </td>
                  <td className="border border-slate-600">
                    The Orchid Hotel Shimla
                  </td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Manali</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">3</td>
                  <td className="border border-slate-600">
                    Pristine Inn / Swastik Grand
                  </td>
                  <td className="border border-slate-600">
                    Olive Country Resort/ Sandhya Resort & Spa
                  </td>
                  <td className="border border-slate-600">
                    Foxoso Coral Resort And Spa / Mastiff Grand Manali Resort
                  </td>
                  <td className="border border-slate-600">
                    ManuAllaya Resort spa / Tiaraa Hotels & Resorts
                  </td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Chandigarh</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">1</td>
                  <td className="border border-slate-600">Shagun</td>
                  <td className="border border-slate-600">Royal Crown</td>
                  <td className="border border-slate-600">
                    Velvet Clarks Exotica
                  </td>
                  <td className="border border-slate-600">Best western</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 2 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">21300</td>
                  <td className="border border-slate-600">22500</td>
                  <td className="border border-slate-600">27600</td>
                  <td className="border border-slate-600">35300</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">16400</td>
                  <td className="border border-slate-600">17600</td>
                  <td className="border border-slate-600">22700</td>
                  <td className="border border-slate-600">30400</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">21300</td>
                  <td className="border border-slate-600">22500</td>
                  <td className="border border-slate-600">27600</td>
                  <td className="border border-slate-600">35300</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 6 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">18100</td>
                  <td className="border border-slate-600">19300</td>
                  <td className="border border-slate-600">24400</td>
                  <td className="border border-slate-600">32100</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 8 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">19900</td>
                  <td className="border border-slate-600">21100</td>
                  <td className="border border-slate-600">26200</td>
                  <td className="border border-slate-600">33900</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 10 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">18200</td>
                  <td className="border border-slate-600">19400</td>
                  <td className="border border-slate-600">24500</td>
                  <td className="border border-slate-600">32200</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 12 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">17070</td>
                  <td className="border border-slate-600">18270</td>
                  <td className="border border-slate-600">23370</td>
                  <td className="border border-slate-600">31070</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">12 Year + Adult</td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">8000</td>
                  <td className="border border-slate-600">8850</td>
                  <td className="border border-slate-600">12500</td>
                  <td className="border border-slate-600">20320</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Child 6 to 12 Years
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">6850</td>
                  <td className="border border-slate-600">7560</td>
                  <td className="border border-slate-600">10650</td>
                  <td className="border border-slate-600">17800</td>
                </tr>
              </tbody>
            </table>
            <ol>
              <li>
                Pickup from Chandigarh and drop at Chandigarh Railway station By
                Dzire/ SUV / 12 Seater Tempo.
              </li>
              <li>06 Nights / 07 Days Accommodation in well-appointed Room</li>
              <li>06 Breakfasts and 06 Dinner at hotel</li>
              <li>Rates are including of GST.</li>
            </ol>
            <ol>
              <li>
                <strong>
                  Day 1 - ARRIVE CHANDIGARH AND DRIVE TO SHIMLA (6HRS)
                </strong>
                <p>
                  After arriving at the Chandigarh airport/ railway station, our
                  tour representative meets you and helps you with an assisted
                  transfer to Shimla, by road. After reaching Shimla, check-in
                  at the hotel. Spend the evening as per your own liking. Fondly
                  referred to as the “Queen of Hills’ Shimla is the capital city
                  of Himachal Pradesh, which boasts of mesmerizing scenery and a
                  laid-back atmosphere. During your leisure time, explore the
                  nearby places on your own such as visiting the Mall, the Ridge
                  and so on. Stay overnight.
                </p>
              </li>
              <li>
                <strong>
                  Day 2 - SHIMLA - KUFRI EXCURSION WITH LOCAL SIGHT SEEING
                </strong>
                <p>
                  Today morning after a filling delicious breakfast visit Kufri.
                  Kufri derives its name from ‘Kufr’ which means lake. It is
                  especially popular among trekkers. The salubrious climate and
                  stunning natural beauty makes Kufri a perfect summer getaway.
                  Kufri is the venue for annual winter sports festival held in
                  February. Kufri is also renowned for other adventures
                  activities such as skiing tobogganing (sliding downhill on a
                  sled) etc. Kufri has the oldest skiing slopes in Himachal
                  Pradesh. Kufri is also a haven for nature enthusiasts and
                  wildlife lovers as the Himalayan Nature Park is situated on
                  the outskirts of the town. The Park is home to over 180
                  varieties of fauna.Thereafter visit the famous mall road of
                  Shimla. Visit the Scandal Point Jhakoo Temple (private
                  vehicles do not have access to Jhakoo temple because of the
                  location. The guests can take a shared taxi available on Mall
                  Road and visit the temple. It’s advisable to visit the temple
                  before it turns dark; hence the guests can first visit the
                  temple and then spend time on the mall road.) After ample of
                  Shopping time return to your hotel for a mouth watering
                  dinner. Overnight in the resort.
                </p>
              </li>
              <li>
                <strong>Day 3 - SHIMLA TO MANALI (9HRS)</strong>
                <p>
                  Have your breakfast and begin driving Manali, one of the most
                  popular hill stations of India. Upon arriving, complete the
                  check- in formalities at the hotel. The evening is at leisure.
                  Flocked by honeymooners and family vacationers all around the
                  year, Manali is dotted with a number of attractions. Stay
                  overnight.
                </p>
              </li>
              <li>
                <strong>
                  Day 4 - MANALI - LOCAL SIGHT SEEING & SOLANG VALLEY VISIT
                </strong>
                <p>
                  After breakfast get set to visit the popular tourist
                  attractions of Manali. Morning we proceed for Solang Valley
                  where we can enjoy adventure activities like Zorbing,
                  Paragliding. Later you can also visit Tibetan Monastery- not
                  only a place of worship but also a Tibetan culture centre, the
                  natural springs and sandstone temples at Vashist Village,
                  Hadimba Devi Temple- dedicated to Hidimba, wife of Bhim-one of
                  the 5 Pandava brother of the Hindu epic Mahabharata and the
                  Manu Temple- an old temple dedicated to sage Manu. Club House
                  In evening we return back to hotel or free time at mall road.
                  Overnight at Manali.
                </p>
              </li>
              <li>
                <strong>
                  Day 5 - MANALI - ROHTANG PASS EXCURSION (50KMS) - AT OWN COST
                </strong>
                <p>
                  After early breakfast enjoy a breathtaking drive to Snow Point
                  on the road to Rohtang Pass (Closed On Tuesdays). Known for
                  its scenic beauty Rohtang Pass holds strategic importance for
                  India. Manali-Leh Highway a part of NH 21 transverses Rohtang
                  Pass. The name Rohtang means ‘ground of corpses’ due to the
                  number of people who lost their lives trying to cross it.
                  Besides Lahaul and Spiti Rohtang Pass also serves as the
                  gateway to Pangi and the valley of Leh. The Pass offers
                  beautiful sights of glaciers peaks Lahaul Valley and the
                  Chandra River. The twin peaks of Geypan are also visible from
                  Rohtang. Later visit the famous Solang Valley. Solang Valley
                  locally known as Solang Nullah is renowned as the preferred
                  location for adventure activities in Himachal Pradesh. For
                  those who love the rush of adrenaline; there is no place
                  better than Solang in Kullu Valley to indulge in parachuting
                  paragliding skating and zorbing. It is 8500 feet above mean
                  sea level. Solang is not just about adventure activities. Its
                  lush greenery apple orchards snowcapped peaks and lakes
                  attract lacs of visitors. Return to hotel by the evening.
                  Enjoy Dinner and overnight in hotel.
                </p>
              </li>
              <li>
                <strong>Day 6 - MANALI TO CHANDIGARH (09HRS)</strong>
                <p>
                  After breakfast, complete the check-out formalities at the
                  hotel and then proceed for Chandigarh. Chandigarh - is the
                  first planned modern city of India designed by the French
                  architect Le Corbusier. Chandigarh is a city in India that
                  serves as the capital of two states - Punjab and Haryana.
                  However, the city does not belong to either state. Rather, the
                  city is administered by the federal government and hence
                  classified as a union territory.
                </p>
              </li>
              <li>
                <strong>
                  Day 7 – CHANDIGARH HOTEL TO CHANDIGARH AIRPORT OR STATION DROP
                </strong>
                <p>
                  After breakfast we checkout from hotel and proceed for
                  Chandigarh airport/railway station to board the flight/Train
                  back to home.
                </p>
              </li>
            </ol>
          </div>
          <div className="mt-10 mb-20 pt-5 pb-5 max-w-full overflow-auto">
            <h1 className="mt-10 mb-10">
              <strong style={{ color: "#68391B", fontSize: "30px" }}>
                {" "}
                2N Shimla-3N Manali-1N Dharamshala- 2 N Dalhousie 1 N Amritsar,
                EX-Chandigarh
              </strong>
            </h1>
            <table className="border-collapse border border-slate-500 max-w-full">
              <thead>
                <tr className="border border-slate-600">
                  <th className="border border-slate-600">Place</th>
                  <th className="border border-slate-600">Meal</th>
                  <th className="border border-slate-600">Night</th>
                  <th className="border border-slate-600">Deluxe</th>
                  <th className="border border-slate-600">Super Deluxe</th>
                  <th className="border border-slate-600">Luxury</th>
                  <th className="border border-slate-600">Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">SHIMLA</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">2</td>
                  <td className="border border-slate-600">Katson Blue</td>
                  <td className="border border-slate-600">
                    The Royal Regency/CK international
                  </td>
                  <td className="border border-slate-600">
                    Kufri Pacific / Agate Pandora
                  </td>
                  <td className="border border-slate-600">
                    The Orchid Hotel Shimla
                  </td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Manali</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">3</td>
                  <td className="border border-slate-600">
                    Pristine Inn / Swastik Grand
                  </td>
                  <td className="border border-slate-600">
                    Olive Country Resort/ Sandhya Resort & Spa
                  </td>
                  <td className="border border-slate-600">
                    Foxoso Coral Resort And Spa / Mastiff Grand Manali Resort
                  </td>
                  <td className="border border-slate-600">
                    ManuAllaya Resort spa / Tiaraa Hotels & Resorts
                  </td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Dharamshala</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">1</td>
                  <td className="border border-slate-600">Harmony Blue</td>
                  <td className="border border-slate-600">AK Continental</td>
                  <td className="border border-slate-600">
                    Katson Castle Hotel
                  </td>
                  <td className="border border-slate-600">Nibaana</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Dalhousie</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">2</td>
                  <td className="border border-slate-600">Amara Blue Magnet</td>
                  <td className="border border-slate-600">Sagrika Resort</td>
                  <td className="border border-slate-600">Nature Valley</td>
                  <td className="border border-slate-600">Inderprastha</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">Amritsar</td>
                  <td className="border border-slate-600">MAP</td>
                  <td className="border border-slate-600">1</td>
                  <td className="border border-slate-600">Grand Heridey</td>
                  <td className="border border-slate-600">Amritsar Grand</td>
                  <td className="border border-slate-600">Bloom Boutique</td>
                  <td className="border border-slate-600">Best western</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 2 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">31500</td>
                  <td className="border border-slate-600">33125</td>
                  <td className="border border-slate-600">41600</td>
                  <td className="border border-slate-600">50700</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with Sedan
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">24500</td>
                  <td className="border border-slate-600">26050</td>
                  <td className="border border-slate-600">34560</td>
                  <td className="border border-slate-600">43670</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 4 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">31575</td>
                  <td className="border border-slate-600">33125</td>
                  <td className="border border-slate-600">41625</td>
                  <td className="border border-slate-600">50725</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 6 Pax with SUV
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">26870</td>
                  <td className="border border-slate-600">28350</td>
                  <td className="border border-slate-600">36920</td>
                  <td className="border border-slate-600">46100</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 8 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">28100</td>
                  <td className="border border-slate-600">29625</td>
                  <td className="border border-slate-600">38125</td>
                  <td className="border border-slate-600">47225</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 10 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">25950</td>
                  <td className="border border-slate-600">27500</td>
                  <td className="border border-slate-600">36000</td>
                  <td className="border border-slate-600">45100</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Per Person min. 12 Pax Tempo
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">24533</td>
                  <td className="border border-slate-600">26100</td>
                  <td className="border border-slate-600">34600</td>
                  <td className="border border-slate-600">43700</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">12 Year + Adult</td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">12300</td>
                  <td className="border border-slate-600">13300</td>
                  <td className="border border-slate-600">19250</td>
                  <td className="border border-slate-600">29280</td>
                </tr>
                <tr className="border border-slate-600">
                  <td className="border border-slate-600">
                    Child 6 to 12 Years
                  </td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600"></td>
                  <td className="border border-slate-600">10500</td>
                  <td className="border border-slate-600">11500</td>
                  <td className="border border-slate-600">16500</td>
                  <td className="border border-slate-600">22500</td>
                </tr>
              </tbody>
            </table>
            <h1>
              Here's the provided information converted into an ordered list
              (ol) in HTML:
            </h1>
            <ol>
              <li>
                Pickup from Chandigarh and drop at Chandigarh Railway station By
                Dzire/ SUV / 12 Seater Tempo.
              </li>
              <li>09 Nights / 10 Days Accommodation in well-appointed Room</li>
              <li>09 Breakfasts and 09 Dinner at hotel</li>
              <li>Rates are including of GST.</li>
            </ol>
            Note: All Sightseeing Points subject to depends on weather & road
            conditions.
            <h1>
              <strong>Itinerary</strong>
            </h1>
            <ol>
              <li>
                <strong>
                  Day 1 - ARRIVE AT CHANDIGARH & PROCEED TO SHIMLA (5HRS.)
                </strong>
                <p>
                  Upon arrival at Chandigarh Airport, you will be welcomed by
                  our representative & transferred to Shimla by road. Shimla,
                  the capital of Himachal Pradesh. This city was the former
                  summer capital of the British. It is one of the top tourist
                  destinations in India owing to its mild climate, meandering
                  streets and snow-clad mountains. Often referred to as the
                  'Queen of Hill Stations'.
                </p>
                <p>
                  Once you reach Shimla, complete the check-in formalities at
                  the Hotel and take some rest. Night stay at the Hotel.
                </p>
              </li>
              <li>
                <strong>
                  Day 2 - SHIMLA - KUFRI EXCURSION WITH LOCAL SIGHT SEEING
                </strong>
                <p>
                  Today morning after a filling, delicious breakfast visits
                  Kufri. Kufri derives its name from 'Kufr' which means lake. It
                  is prevalent among trekkers. The salubrious climate and
                  stunning natural beauty make Kufri a perfect summer getaway.
                  Kufri is the venue for annual winter sports festival held in
                  February.
                </p>
                <p>
                  Thereafter visit the famous mall road of Shimla. Visit the
                  Scandal Point Jhakoo Temple. After ample shopping at mall
                  road, we returned to your hotel for a mouth-watering dinner.
                  Overnight in the resort.
                </p>
              </li>
              <li>
                <strong>Day 3 - SHIMLA TO MANALI (9HRS.)</strong>
                <p>
                  Have your breakfast and begin driving Manali, one of the most
                  popular hill stations of India. Upon arriving, complete the
                  check-in formalities at the hotel. The evening is at leisure.
                  Flocked by honeymooners and family vacationers all around the
                  year, Manali is dotted with a number of attractions. Stay
                  overnight.
                </p>
              </li>
              <li>
                <strong>
                  Day 4 - MANALI - ROHTANG PASS EXCURSION (50KMS) - AT OWN COST.
                </strong>
                <p>
                  After early breakfast enjoy a breathtaking drive to Snow Point
                  on the road to Rohtang Pass (Closed On Tuesdays). Known for
                  its scenic beauty Rohtang Pass holds strategic importance for
                  India. Later visit the famous Solang Valley. Return to hotel
                  by the evening. Enjoy Dinner and overnight in hotel.
                </p>
              </li>
              <li>
                <strong>
                  Day 5 - MANALI - LOCAL SIGHT SEEING & SOLANG VALLEY VISIT
                </strong>
                <p>
                  The morning we proceed for Solang Valley, where we can enjoy
                  adventure activities like Zorbing, Paragliding. Later you can
                  also visit Tibetan Monastery, natural springs and sandstone
                  temples at Vashist Village, Hadimba Devi Temple, and the Manu
                  Temple. In the evening, we return to the hotel or free time at
                  mall road. Overnight at Manali.
                </p>
              </li>
              <li>
                <strong>Day 6 MANALI TO DHARAMSALA (08HRS.)</strong>
                <p>
                  After having breakfast, hit the road to Dharamshala. On the
                  way, let the cool breeze come while treating your eyes to the
                  scenic, spread all around. After arrival in Dharamshala,
                  check-in at the hotel. Spend the evening as per your own
                  liking.
                </p>
              </li>
              <li>
                <strong>Day 7 DHARAMSALA TO DALHOUSIE (05HRS.)</strong>
                <p>
                  After ravishing breakfast check-out from the hotel. We move on
                  to our next destination Dalhousie - a famous hill station in
                  the lap of Himachal Pradesh known for its rich colonial
                  architecture and preserves some of the beautiful churches.
                  Arrive at the hotel, freshen up and enjoy your evening in the
                  midst of snowcapped peaks with a hot cup of tea. Overnight at
                  the hotel.
                </p>
              </li>
              <li>
                <strong>Day 8 - DALHOUSIE - KHAJJIAR - DALHOUSIE</strong>
                <p>
                  After breakfast, you are going for a day-trip to the amazing
                  locations of Khajjiar-also referrred as 'Mini-Switzerland of
                  India'. Return to Dalhousie for an overnight stay. Relish
                  exotic dinner and enjoy your stay at hotel.
                </p>
              </li>
              <li>
                <strong>
                  Day 9 DALHOUSIE TO AMRITSAR (WITH WAGAH BORDER) (06HRS)
                </strong>
                <p>
                  After breakfast, check out from Hotel & proceed for Amritsar
                  by road. On arrival check-in at hotel and later visit Indo-Pak
                  Wagah Border to watch Flag Retreat Ceremony. Overnight stay at
                  Hotel.
                </p>
              </li>
              <li>
                <strong>Day 10 - AMRITSAR TO CHANDIGARH DROP (06HRS)</strong>
                <p>
                  After breakfast, we checkout from hotel and proceed for
                  Chandigarh by road. On arrival, you will be transferred to the
                  airport/railway station. Tour end with happy memories.
                </p>
              </li>
            </ol>
          </div>

          <div className="mt-10 mb-20 pt-5 pb-5 max-w-full overflow-auto">
            <h1 className="mt-10 mb-10">
              <strong style={{ color: "#68391B", fontSize: "30px" }}>
                Delhi-2N Shimla-3N Manali-1N Chandigarh-Chandigarh
              </strong>
            </h1>
            <table className="border-collapse border border-slate-500 max-w-full">
              <tr className="border border-slate-600">
                <th className="border border-slate-600">Place</th>
                <th className="border border-slate-600">Meal</th>
                <th className="border border-slate-600">Night</th>
                <th className="border border-slate-600">6 Night PKG</th>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">SHIMLA</td>
                <td className="border border-slate-600">MAP</td>
                <td className="border border-slate-600">2</td>
                <td className="border border-slate-600">Clarkes Hotel</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">Manali</td>
                <td className="border border-slate-600">MAP</td>
                <td className="border border-slate-600">3</td>
                <td className="border border-slate-600">Clarks Inn Suites</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">Chandigarh</td>
                <td className="border border-slate-600">MAP</td>
                <td className="border border-slate-600">1</td>
                <td className="border border-slate-600">
                  Velvet Clarks Exotica
                </td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 2 Pax with Sedan
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">23700</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 4 Pax with Sedan
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">18500</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 4 Pax with SUV
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">23500</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 6 Pax with SUV
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">20200</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 08 Pax Tempo
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">21800</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 10 Pax Tempo
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">20120</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 12 Pax Tempo
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">19000</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">12 Year + Adult</td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">9500</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">Child 6 to 12 Years</td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">7000</td>
              </tr>
            </table>
          </div>
          <div className="mt-10 mb-20 pt-5 pb-5 max-w-full overflow-auto">
            <h1 className="mt-10 mb-10">
              <strong style={{ color: "#68391B", fontSize: "30px" }}>
                Delhi-2N Shimla-3N Manali-1N Chandigarh-Chandigarh
              </strong>
            </h1>
            <table className="border-collapse border border-slate-500 max-w-full">
              <tr className="border border-slate-600">
                <th className="border border-slate-600">Place</th>
                <th className="border border-slate-600">Meal</th>
                <th className="border border-slate-600">Night</th>
                <th className="border border-slate-600">6 Night PKG</th>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">SHIMLA</td>
                <td className="border border-slate-600">MAP</td>
                <td className="border border-slate-600">2</td>
                <td className="border border-slate-600">The Orchid Hotel</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">Manali</td>
                <td className="border border-slate-600">MAP</td>
                <td className="border border-slate-600">3</td>
                <td className="border border-slate-600">Foxoso Hotel</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">Chandigarh</td>
                <td className="border border-slate-600">MAP</td>
                <td className="border border-slate-600">1</td>
                <td className="border border-slate-600">
                  Velvet Clarks Exotica
                </td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 2 Pax with Sedan
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">29500</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 4 Pax with Sedan
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">24500</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 4 Pax with SUV
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">25000</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 6 Pax with SUV
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">23200</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 08 Pax Tempo
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">27375</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 10 Pax Tempo
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">25800</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">
                  Per Person min. 12 Pax Tempo
                </td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">24750</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">12 Year + Adult</td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">13650</td>
              </tr>
              <tr className="border border-slate-600">
                <td className="border border-slate-600">Child 6 to 12 Years</td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600"></td>
                <td className="border border-slate-600">11700</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="container max-w-xl mx-auto ">
          <h1
            className="text-red-800 font-semibold text-xl md:text-xl md:!leading-[120%] lg:text-xl dark:text-neutral-100 max-w-4xl"
            style={{ color: "#68391B" }}
          >
            Send Enquiry Regarding the packgage {packageRequested}
          </h1>
          <form
            className="grid grid-cols-1 gap-6"
            method="post"
            onSubmit={(e: any) => handleSubmit(e)}
          >
            <label className="block">
              <Label>Full name</Label>

              <Input
                placeholder="Example Doe"
                name="name"
                type="text"
                value={initialValues.name}
                className="mt-1"
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Email address</Label>

              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                value={initialValues.email}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Message</Label>
              <Textarea
                className="mt-1 focus:text-white"
                rows={6}
                name="message"
                value={initialValues.message}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <div>
              <ButtonPrimary type="submit">Send Message</ButtonPrimary>
            </div>
          </form>
        </div>
      </>
    );
  };

  //   useEffect(() => {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const packageRequested = urlParams.get("package");
  //     if (packageRequested) {
  //       setPackageRequested(packageRequested);
  //       initialValues.packageRequested = packageRequested;
  //       setInitialValues({
  //         ...initialValues,
  //         packageRequested: packageRequested,
  //       });
  //     }
  //   }, []);

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Solitary Packages</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://parthasarathitourtravel.com/wp-content/uploads/2023/02/manali.jpg"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  );
};

export default ManaliPackage;
