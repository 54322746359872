// import { GuestsObject } from "components/HeroSearchForm/type";
// import NcInputNumber from "components/NcInputNumber/NcInputNumber";
// import React, { useEffect, useState } from "react";
// import { FC } from "react";
// export interface GuestsInputProps {
//   defaultValue?: GuestsObject;
//   onChange?: any;
//   className?: string;
// }

// const GuestsInput: FC<GuestsInputProps> = ({
//   defaultValue,
//   onChange,
//   className = "",
// }) => {
//   const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
//     defaultValue?.guestAdults || 1
//   );
//   const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
//     defaultValue?.guestChildren || 0
//   );
//   const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
//     defaultValue?.guestInfants || 0
//   );

//   useEffect(() => {
//     setGuestAdultsInputValue(defaultValue?.guestAdults || 1);
//   }, [defaultValue?.guestAdults]);
//   useEffect(() => {
//     setGuestChildrenInputValue(defaultValue?.guestChildren || 0);
//   }, [defaultValue?.guestChildren]);
//   useEffect(() => {
//     setGuestInfantsInputValue(defaultValue?.guestInfants || 0);
//   }, [defaultValue?.guestInfants]);

//   const handleChangeData = (value: number, type: keyof GuestsObject) => {
//     let newValue = {
//       guestAdults: guestAdultsInputValue,
//       guestChildren: guestChildrenInputValue,
//       guestInfants: guestInfantsInputValue,
//     };
//     if (type === "guestAdults") {
//       setGuestAdultsInputValue(value);
//       newValue.guestAdults = value;
//     }
//     if (type === "guestChildren") {
//       setGuestChildrenInputValue(value);
//       newValue.guestChildren = value;
//     }
//     if (type === "guestInfants") {
//       setGuestInfantsInputValue(value);
//       newValue.guestInfants = value;
//     }
//     onChange && onChange(newValue);
//   };
//   const totalGuests =
//     guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;
//   useEffect(() => {
//     // Notify the parent component of the changes in totalGuests
//     onChange(totalGuests);
//   }, [totalGuests, onChange]);

//   return (
//     <div className={`flex flex-col relative p-5 ${className}`}>
//       <span className="mb-5 block font-semibold text-xl sm:text-2xl">
//         {`Who's coming?`}
//       </span>
//       <NcInputNumber
//         className="w-full"
//         defaultValue={guestAdultsInputValue}
//         onChange={(value) => handleChangeData(value, "guestAdults")}
//         max={20}
//         label="Adults"
//         desc="Ages 13 or above"
//       />
//       <NcInputNumber
//         className="w-full mt-6"
//         defaultValue={guestChildrenInputValue}
//         onChange={(value) => handleChangeData(value, "guestChildren")}
//         max={20}
//         label="Children"
//         desc="Ages 2–12"
//       />

//       <NcInputNumber
//         className="w-full mt-6"
//         defaultValue={guestInfantsInputValue}
//         onChange={(value) => handleChangeData(value, "guestInfants")}
//         max={20}
//         label="Infants"
//         desc="Ages 0–2"
//       />
//     </div>
//   );
// };

// export default GuestsInput;

import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { useEffect, useState } from "react";
import { FC } from "react";
export interface GuestsInputProps {
  defaultValue?: GuestsObject;
  onChange?: any;
  className?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  className = "",
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue?.guestAdults || 1
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue?.guestChildren || 0
  );
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    defaultValue?.guestInfants || 0
  );

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue?.guestAdults || 1);
  }, [defaultValue?.guestAdults]);
  useEffect(() => {
    setGuestChildrenInputValue(defaultValue?.guestChildren || 0);
  }, [defaultValue?.guestChildren]);
  useEffect(() => {
    setGuestInfantsInputValue(defaultValue?.guestInfants || 0);
  }, [defaultValue?.guestInfants]);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
    onChange && onChange(newValue);
  };
  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;
  useEffect(() => {
    onChange(totalGuests);
  }, [totalGuests, onChange]);

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`Who's coming?`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={(value) => handleChangeData(value, "guestAdults")}
        max={20}
        min={1}
        label="Adults"
        desc="Ages 13 or above"
        type="guests"
       />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestChildrenInputValue}
        onChange={(value) => handleChangeData(value, "guestChildren")}
        max={20}
        min={0}
         label="Children"
        desc="Ages 2–12"
        type="guests"
      />

      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestInfantsInputValue}
        onChange={(value) => handleChangeData(value, "guestInfants")}
        max={20}
        min={0}
        label="Infants"
        desc="Ages 0–2"
        type="guests"
      />
    </div>
  );
};

export default GuestsInput;

