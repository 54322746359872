import React, { FC } from "react";
// type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  prices: {
    sunday: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    [key: string]: number;
  };
  seasonPrice?: {
    from: string;
    to: string;
    price: number;
    [key: string]: any;
  }[];
  impDates: string[];
  blockPrice: string;
}
const DatePickerCustomDay: FC<Props> = ({
  dayOfMonth,
  date,
  prices,
  impDates,
  blockPrice,
  seasonPrice,
}) => {
  const getDayName = (dayOfWeek: number): string => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    return days[dayOfWeek];
  };

  const dayOfWeek: number | any = date?.getDay();
  



function getPrice(givenDate: any) {
  givenDate = new Date(givenDate);
  givenDate.setHours(5); 
  givenDate.setMinutes(30); 
  givenDate.setSeconds(0);
  if (!seasonPrice || seasonPrice.length === 0) {
    return 0; 
  }
  for (let i = 0; i < seasonPrice.length; i++) {
    let fromDate = new Date(seasonPrice[i].from);
    let toDate = new Date(seasonPrice[i].to);
    if (givenDate >= fromDate && givenDate <= toDate) {
      return seasonPrice[i].price;
    }
  }
  return 0;
}


  // const price = impDates.includes(date?.toISOString() ?? "")
  //   ? blockPrice
  //   : prices[getDayName(dayOfWeek)];
  const price = impDates.includes(date?.toISOString() ?? "")
    ? blockPrice
    : getPrice(date) ;


  return (
    <div>
      <span className="react-datepicker__day_span">
        <div className="text-lg mt-0" style={{ lineHeight: "1.2rem" }}>
          {/* {dayOfMonth}
           */}
          {date?.getDate()}
          {/* <div className="mt-0 text-xxs text-white-300">₹ 2,000</div> */}
          <div className="mt-0 text-xxs text-white-300">₹{price!==0?price:"-"}</div>
        </div>
      </span>
    </div>
  );
};

export default DatePickerCustomDay;
