import React from "react";
import LogoLight from "../../images/logos/solitary_logo.png";

const LogoSvgLight = () => {
  return (
    <>
      <img src={LogoLight} alt="" />
    </>
  );
};

export default LogoSvgLight;
