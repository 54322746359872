import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Swal from "sweetalert2";
import { axiosInstance } from "index";

export interface BlogSinglePageProps{
  className?:string;
  header?:string;
  content?:string;
}

const BlogSingle: FC<BlogSinglePageProps> =({className="",header,content})=>{
  const [packageRequested,setPackageRequested]=useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    message: "",
    travellers:"",
    packageRequested:""
  });
  const [slug,setSlug]=useState("");
  const [packageDetails,setPackageDetails]=useState<any>()

  
  const replaceOembedWithString = (input:any) => {
    const regex = /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([^"]+)"><\/oembed>/g;
  
    const replacedString = input?.replace(
      regex,
      '<iframe width="100%" height="315" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>'
    );
    setPackageDetails({...packageDetails,content:replacedString})
    return replacedString;
  };



  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value);
    setInitialValues({ ...initialValues, [name]: value });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/support/", initialValues);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Message Sent",
          text: "We will get back to you soon",
          showConfirmButton: true,
          confirmButtonColor: "#A47557",
          // timer: 800,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          confirmButtonColor: "#A47557",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="blue" name="Traveler" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
           {packageDetails?.name}
          </h1>
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div
          id="single-entry-content"
          className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        >
          <div dangerouslySetInnerHTML={{ __html: packageDetails?.content }} />
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 gap-y-3">
              {
                packageDetails && packageDetails?.packageGalleryImages?.map((item:any)=>{
                  return <img src={item.path} className="w-full h-48 aspect-auto" alt={item.mediable_type}/>
                })
              }
              
              </div>      
          </div>
        </div>
        <hr></hr>
        <div className="container max-w-xl md:max-w-2xl lg:max-w-4xl mx-auto">
          <h1 className="text-red-800 font-semibold text-xl md:text-xl md:!leading-[120%] lg:text-xl dark:text-neutral-100 max-w-4xl">
            Send Enquiry Regarding the packgage {packageRequested}
          </h1>
          <form
            className="grid grid-cols-1 gap-6"
            method="post"
            onSubmit={(e: any) => handleSubmit(e)}
          >
            <label className="block">
              <Label>Full name</Label>

              <Input
                placeholder="Example Doe"
                name="name"
                type="text"
                value={initialValues.name}
                className="mt-1"
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Email address</Label>

              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                value={initialValues.email}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Number of Travellers</Label>

              <Input
                type="text"
                name="travellers"
                placeholder="Ex: 1 or 2"
                className="mt-1"
                value={initialValues.travellers}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Message</Label>
              <Textarea
                className="mt-1 focus:text-white"
                rows={6}
                name="message"
                placeholder="Enter Your Message"
                value={initialValues.message}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <div>
              <ButtonPrimary type="submit">Send Message</ButtonPrimary>
            </div>
          </form>
        </div>
      </>
      
    );
  };




  // useEffect(()=>{
  //   if(packageDetails && packageDetails?.content!==null){
  //     setPackageDetails({...packageDetails,content:replaceOembedWithString(packageDetails?.content)})
  //   }
  // },[packageDetails])


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const packageName = urlParams.get("package");
    console.log(packageName);
    if (packageName) {
      setSlug(packageName);
      setInitialValues({...initialValues,packageRequested:packageName})
    }
  }, []);

  


  const getPackageBySlug=async()=>{
    try {
      const response=await axiosInstance.get("/package/slug/"+slug);
      console.log(response);
      console.log(response.data.data)
      if(response.status===200){
        const newContent=replaceOembedWithString(response.data.data.content);

        setPackageDetails({...response.data.data,content:newContent})
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    if(slug!==" " && slug!==undefined && slug!==null){
      getPackageBySlug();
    }
  },[slug])

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Solitary Packages</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl h-[70vh]"
        containerClassName="container my-10 sm:my-12 "
        src={packageDetails?.path}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
      
    </div>
  );
};

export default BlogSingle;
