// import React, { FC, useEffect, useState } from "react";
// import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
// import { ExperiencesDataType, StayDataType } from "data/types";
// import Pagination from "shared/Pagination/Pagination";
// import TabFilters from "./TabFilters";
// import Heading2 from "components/Heading/Heading2";
// import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
// import StayCard from "components/StayCard/StayCard";
// export interface SectionGridFilterCardProps {
//   className?: string;
//   data?: StayDataType[];
// }

// const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter(
//   (_, i) => i < 8
// );

// const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
//   className = "",
//   data
// }) => {
//   // const [city,setCity] = useState("Goa");
//   // const heading=`Experience in ${city}`;

//   // useEffect(() => {
//   //   data ? setCity(data[0].city) : setCity("Goa");
//   // }, []);
//   return (
//     <div
//       className={`nc-SectionGridFilterCard ${className}`}
//       data-nc-id="SectionGridFilterCard"
//     >
//       <Heading2
//         heading="Listing from the city "

//       />

//       <div className="mb-8 lg:mb-11">
//         <TabFilters />
//       </div>
//       <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
//         {data?.map((stay) => (
//           <StayCard key={stay.id} data={stay} />
//         ))}
//       </div>
//       <div className="flex mt-16 justify-center items-center">
//         {/* <Pagination /> */}
//       </div>
//     </div>
//   );
// };

// export default SectionGridFilterCard;

import { CSSProperties, FC, useEffect, useState } from "react";
import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import { axiosInstance } from "../../index";
import { useSearch } from "SearchContext";
import { useLocation, useParams } from "react-router-dom";
import { setLocale } from "yup";

import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#68391B",
};

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}
interface Price {
  from: string;
  to: string;
  price: number;
}
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
}) => {
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [total, setTotal] = useState<number>(0);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState<any>("");

  function getLowestPrice(seasonPrice: Price[]): number {
    if (seasonPrice.length === 0) {
      return 0;
    }
    let lowestPrice = seasonPrice[0].price;
    for (let i = 1; i < seasonPrice.length; i++) {
      if (seasonPrice[i].price < lowestPrice) {
        lowestPrice = seasonPrice[i].price;
      }
    }

    return lowestPrice;
  }

  const getPropertiesByCity = async () => {
    try {
      setLoading((prev)=>{
        return !prev
      });
      const response = await axiosInstance.get(`/users/properties/${city}`);
      if (response.status === 200) {
        setProperties((prevState) => {
          return response.data.data
            .filter((ele: any) => ele.room.length > 0)
            .map((item: any) => {
              let minPrice = Infinity;
              item.room.forEach((room: any) => {
                if (room.price) {
                  const roomMinPrice = getLowestPrice(room.price);
                  minPrice = Math.min(minPrice, roomMinPrice);
                }
              });
              return { ...item, price: minPrice };
            });
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev)=>{
        return !prev
      });
    }
  };

  useEffect(() => {
    getPropertiesByCity();
  }, [city]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCity(params.get("city"));
  }, []);

  const handlePageChange = async (selectedPage: number) => {
    setPageNumber(selectedPage);
  };



  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 />

      <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        
        {!loading ? (
          <>
            {properties?.map((stay, index) => (
              <StayCard key={index} data={stay} />
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center">
            <ClipLoader
              color={"#68391B"}
              loading={loading}
              cssOverride={override}
              size={250}
            />
          </div>
        )}
        {!loading && properties.length === 0 && (
          <div className="flex justify-start">
            <h1
              className="color-brown-900"
              style={{ fontSize: "40px", color: "#68391B" }}
            >
              Coming Soon
            </h1>
          </div>
        )}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <Pagination /> */}
        <Pagination total={total} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
