/* eslint-disable @typescript-eslint/no-unused-vars */
import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import rightImgPng from "images/our-features-2.png";
import { useEffect, useState, CSSProperties } from "react";
import { axiosInstance } from "../../index";
import {Loader,Placeholder} from 'rsuite';
import ClipLoader from "react-spinners/ClipLoader";
import { useSearch } from "SearchContext";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#68391B",
};

function PageHome() {
  // const [properties, setProperties] = useState([]);
  // const [cities, setCities] = useState([]);
  const [loading,setLoading]=useState(false);
  const {cities}=useSearch();
  // const getAllProperties = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axiosInstance.get(
  //       "/users/hotels/?page=1&pageSize=10"
  //     );
  //     setProperties(
  //       response.data.data.filter((item: any) => item.room?.length > 0)
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }finally{
  //     setLoading(true);
  //   }
  // };

  // const getCities = async () => {
  //   try {
  //     setLoading(true)
  //     const res = await axiosInstance.get("/cities");
  //     if (res && res.data) {
  //       console.log(res.data);
  //       setCities(res.data.data);
  //     }
  //   } catch (error: any) {
  //     console.log(error.message);
  //   }finally{
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   // getAllProperties();
  //   getCities();
  // }, []);
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />
        {loading ? (
          <ClipLoader
            // color={color}
            loading={loading}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          // <div>
          //   <Placeholder.Paragraph rows={8} />
          //   <Loader center content="loading..." />
          // </div>

        ) : (
          <SectionSliderNewCategories
            categories={cities}
            uniqueClassName="PageHome_s1"
          />
        )}
        <SectionOurFeatures type="type2" rightImg={rightImgPng} />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>
        <SectionHowItWork />
        <SectionSubscribe2 />
      
        
        <div className="relative py-16">
          <SectionBecomeAnAuthor />
        </div>

        <SectionGridCategoryBox />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
