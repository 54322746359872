import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Swal from "sweetalert2";
import { axiosInstance } from "index";
import { repeat } from "lodash";
import { fr } from "@faker-js/faker";

export interface BlogSinglePageProps {
  className?: string;
  header?: string;
  content?: string;
}

const LadakhPackage: FC<BlogSinglePageProps> = ({
  className = "",
  header,
  content,
}) => {
  const [packageRequested, setPackageRequested] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    message: "",
    packageRequested: "Ladakh",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value);
    setInitialValues({ ...initialValues, [name]: value });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/support/", initialValues);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Message Sent",
          text: "We will get back to you soon",
          showConfirmButton: true,
          confirmButtonColor: "#A47557",
          // timer: 800,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          confirmButtonColor: "#A47557",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge
            href="##"
            color="blue"
            name="Ladakh"
            type="package"
            icon={"Mountain"}
          />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Memorable Ladakh
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Tourism is one of an economic contributor to the union territory of
            Ladakh in Northern India. The union territory is sandwiched between
            the Karakoram mountain range to the north and the Himalayas to the
            south and is situated at the height of 11,400 ft. Ladakh is composed
            of the Leh and Kargil districts.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };
  
  const renderContent = () => {
    return (
      <>
        <div
          id="single-entry-content"
          className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        >
          <div>
            <h6 className="text-black font-bold">
              5 Nights/ 6 Days (3 Leh + 01 Nubra+01 Pangong )
            </h6>
            <ol
              className="list-square list-inside text-black font-semibold"
              style={{ listStyleType: "square" }}
            >
              <li>
                <strong>Min 6 pax : </strong>19499/- 
              </li>
              <li>
                <strong>Min 4 Pax : </strong>22499/-
              </li>
              <li>
                <strong>Min 2 Pax : </strong>31499/-
              </li>
            </ol>
          </div>

          <div className="max-w-screen-md mx-auto border-red-100 dark:border-neutral-700">
            <h3>Hotels</h3>
            <ul
              className="list-square text-black sm:d-block"
              style={{
                listStyleType: "disc",
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
              }}
            >
              <li>
                <strong className="text-brown-900">Leh:</strong>
                <ol>
                  <li>Nature's Land</li>
                  <li>Ladakh Delight </li>
                  <li>Hotel Togochey Or Similar</li>
                </ol>
              </li>
              <li>
                <strong className="text-brown-900">Nubra valley :</strong>
                <ol>
                  <li>De Nubra Camp</li>
                  <li>Neycho Resort</li>
                  <li>Chamba Resort or Similar</li>
                </ol>
              </li>
              <li>
                <strong className="text-white-900">Pangong :</strong>
                <ol>
                  <li>100 Sky Camp</li>
                  <li>White heaven</li>
                  <li>High Lake Hut or Similar</li>
                </ol>
              </li>
            </ul>
          </div>

          <h3>Inclusions in the Package</h3>
          <ul>
            <li>✔Welcome Drink</li>
            <li>✔Hotels/Camps Stay</li>
            <li>✔B’fast & Dinner</li>
            <li>✔SUV Car</li>
            <li>✔All Permits</li>
          </ul>
          <p> </p>
          <p>Intersting Facts About This Place :</p>
          <blockquote>
            <p>
              This makes Siachin Glacier, the world's highest battlefield at the
              height of 6000 m above mean sea level. This place also has the
              world's highest telephone booth. Even during the mid- day, the
              temperature can go as low as -14⁰C
            </p>
          </blockquote>
          <figure>
            <img
              src="https://static.toiimg.com/photo/87726041.cms"
              alt="Blog Page"
              className="rounded-2xl"
            />
          </figure>

          <h3>Tour Itenary</h3>

          <ul>
            <li>
              <strong>● Day 1</strong> - arrival leh & shanti stupa
              sightseeing,leh market
            </li>
            <li>
              <strong>● Day 2</strong> - leh sightseeing (hall of fame ,
              gurudwara pathar sahib,megnetic hills,kali mata temple and sangam
              point.
            </li>
            <li>
              <strong>● Day 3</strong> - Leh to Nubra valley via khardung-La
              pass
            </li>
            <li>
              <strong>● Day 4</strong> - Nubra valley to Pangong tso via shayok
              river.
            </li>
            <li>
              <strong>● Day 5</strong> - Pangong tso to Leh
            </li>
            <li>
              <strong>● Day 6 </strong>- Departure
            </li>
          </ul>
        </div>
        <div className="container max-w-xl mx-auto">
          <h1 className="text-red-800 font-semibold text-xl md:text-xl md:!leading-[120%] lg:text-xl dark:text-neutral-100 max-w-4xl">
            Send Enquiry Regarding the packgage {packageRequested}
          </h1>
          <form
            className="grid grid-cols-1 gap-6"
            method="post"
            onSubmit={(e: any) => handleSubmit(e)}
          >
            <label className="block">
              <Label>Full name</Label>

              <Input
                placeholder="Example Doe"
                name="name"
                type="text"
                value={initialValues.name}
                className="mt-1"
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Email address</Label>

              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                value={initialValues.email}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <label className="block">
              <Label>Message</Label>
              <Textarea
                className="mt-1 focus:text-white"
                rows={6}
                name="message"
                value={initialValues.message}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </label>
            <div>
              <ButtonPrimary type="submit">Send Message</ButtonPrimary>
            </div>
          </form>
        </div>
      </>
    );
  };

  //   useEffect(() => {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const packageRequested = urlParams.get("package");
  //     if (packageRequested) {
  //       setPackageRequested(packageRequested);
  //       initialValues.packageRequested = packageRequested;
  //       setInitialValues({
  //         ...initialValues,
  //         packageRequested: packageRequested,
  //       });
  //     }
  //   }, []);

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Solitary Packages</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://etimg.etb2bimg.com/photo/77779116.cms"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  );
};

export default LadakhPackage;
