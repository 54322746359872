import React from 'react'

const FlightsBooking = () => {
  return (
    <div>
      hey
    </div>
  )
}

export default FlightsBooking
